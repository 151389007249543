import React from 'react'
import { Link } from 'gatsby'

import Button from './button'

import HamburgerIcon from './icons/hamburger'

const classMenuButton =
  'inline-block h-16 mx-6 lg:hidden bg-transparent  border-none text-sm lg:text-lg z-50 font-medium'
const classLinks =
  'inline-block align-middle w-full md:w-auto py-3 lg:py-0 mx-0 md:mx-2 text-center text-lg md:text-lg md:text-right font-medium link'


const Header = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inverse: false,
      menuOpen: false,
      isTop: true
    }

    this.isTopChecker = this.isTopChecker.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  isTopChecker() {
    const isTop = window.scrollY < 50
    if (isTop !== this.state.isTop) {
      this.setState({ isTop })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.isTopChecker)
    if (window.location.pathname.includes('insight/')) {
      this.setState({
        inverse: true
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.isTopChecker)
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  closeMenu() {
    if (this.state.menuOpen) {
      this.setState({
        menuOpen: false
      })
    }
  }

  render() {
    const { company } = this.props
    const { inverse } = this.state

    const MiddleNavLinks = [
      {
        title: 'Home',
        to: '/'
      },
      {
        title: 'Team',
        to: '/team/'
      },
      {
        title: 'Services',
        to: '/services/'
      },
      {
        title: 'Insights',
        to: '/insights/'
      },
      {
        title: "Mission",
        to: "/mission/"
      }
    ]

    const Logo = ({position}) => (
      <div className={`w-full flex lg:relative justify-center lg:justify-start lg:py-0 lg:w-2/12 ${position ? position : 'relative'}`}>

        <Link
          className={`flex w-48 mx-auto lg:mx-none lg:w-full lg:mx-0 link ${inverse ? 'text-black' : 'text-white'}`}
          title={company}
          to="/"
        >
          <img
            className="w-full"
            alt={company}
            src={inverse ? '/Cartograph full color transparent bg.png' : '/Cartograph white orange pointer transparent bg.png'}
          />
        
        </Link>
      </div>
    )

    const MiddleNav = () => (
      <div className="inline-block w-auto capitilize text-center py-8 px-10 lg:py-0">
        {MiddleNavLinks.map((item, index) => (
          <Link
            className={`${classLinks} ${
              MiddleNavLinks.length !== index + 1 ? 'mr-0 md:mr-4' : 'mr-0 md:mr-0 '
            }`}
            key={Math.random()}
            to={item.to}
            activeStyle={{ color: '#f3a870' }}
          >
            {item.title}
          </Link>
        ))}
      </div>
    )

    const RightNav = () => (
      <div className="relative w-full mx-auto lg:w-auto ">
        {inverse ? (
          <Button width={'w-40'}  text="Talk to us" to="/contact/" outlined inverse/>
        ) : (
          <Button width={'w-40'}  text="Talk to us" to="/contact/" outlined />
        )}
      </div>
    )

    const CloseButton = () => (
      <button className={classMenuButton} onClick={this.closeMenu}>
        Close
      </button>
    )

    const MenuButton = () => (
      <button className={classMenuButton} onClick={this.toggleMenu}>
        <HamburgerIcon />
      </button>
    )
    

    return (
      <nav
        aria-label="main-navigation"
        className={`navbar mx-auto flex h-20 lg:h-24 items-center headerGreen  fixed w-full z-40 border-none ${
          this.state.isTop ? `py-0 md:py-6` : `py-0 md:py-4`
        } ${inverse ? 'inverse text-gray-800' : 'text-white'}`}
        role="navigation"
      >
        {/* Mobile nav */}
        <div
          className={`mobile inline-block fixed overflow-y-auto overflow-x-hidden headerGreen text-center left-0 top-0 w-full h-screen z-30
          ${this.state.menuOpen ? `open` : ''} ${this.state.isTop ? `py-8` : `py-4`} text-white`}
        >
          <div className="flex w-full justify-center relative items-center">
            <div className=" lg:hidden absolute top-0 left-0  z-40 ">
              <CloseButton />
            </div>
            <Logo />
          </div>
          <div className="inline-block relative w-full mb-4">
            <MiddleNav />
            <RightNav />
          </div>
        </div>
        {/* Non-Mobile nav */}
        <div className={`  lg:mx-10`}>
          <div className="flex w-full justify-between items-center">
            {/* Mobile Menu */}
            <div className="inline-block lg:hidden relative z-20">
              <MenuButton />
            </div>
            {/* Left */}
            <Logo position={'absolute'}/>
            {/* Middle */}
            <div className="hidden lg:inline-block w-auto relative tc">
              <MiddleNav />
            </div>
            {/* Right */}
            <div className="hidden lg:inline-block relative tr">
              <RightNav />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
